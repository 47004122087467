<template>

</template>

<script>
  export default {
    name: '404'
  }
</script>

<style scoped>

</style>
